.App {
  text-align: center;
}

*{
  overflow-y: hidden
}

@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap');


::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color:none;

  /* background-color: rgba(255, 255, 255, 0.15); */
  backdrop-filter: blur(5px);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color:none;
  /* background-color: rgba(255, 255, 255, 0.15); */
  backdrop-filter: blur(5px);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
}
* {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  max-width: 100vw;
  text-decoration-skip-ink: none;
}


@keyframes animateHeart {
  0% {
    transform: scale(1);
  }

  45% {
    transform: scale(1.05);
  }
  60% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

html,
body {
  width: 100%;
  height: 100vh;
  margin: 0;
}


.heart {
  animation: animateHeart 8s infinite;
}

@media (max-width: 768px) {
  .row {
    padding: 5px !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0.25) inset !important;
}

input:-webkit-autofill {
  background-color: rgba(255, 255, 255, 0.25);
  color: white !important;
  appearance: inherit;
  background-image: none;
}

@keyframes blink {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}
.type-writer {
  animation: blink 1s infinite;
}


.flashCard{

  min-height: 20vh;
  min-width: 300px;
  padding: 10px;
  margin: 10px;
  border-radius:5px;


}

.learnCard{

  min-height: 20vh;
  min-width: 500px;
  max-width: 500px !important;
  padding: 10px;
  margin: 10px;
  border-radius:5px;


}
.writeBox {



}
textarea.form-control:focus{

  outline:none !important;
  box-shadow: none !important;


}



.fadeIn {
  animation: 1s fadeIn forwards;
}

.fadeOut {
  animation: 1s fadeOut forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;

  }
}

@keyframes fadeOut {
  from {
    opacity: 1;

  }
  to {
    opacity: 0;
  }
}



mark {
  position: relative;
  background-color: transparent !important;
}
mark:after {
  background-color: transparent;

  border: .25em solid white;
  border-top: none;
  border-radius: 1.5em 1em 2em 1.5em;
  bottom: -.3em;
  content: '';
  left: -.3em;
  position: absolute;
  right: -.3em;
  top: -.1em;
}
mark:before {
  background-color: transparent;

  border-top: .25em solid white;
  border-radius: .5em;
  content: '';
  height: 1em;
  left: 0;
  position: absolute;
  top: -.3em;
  transform: rotate(-10deg);
  width: 2em;
}


.vertical-center {
  margin: 0 auto;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.w3-display-left{position:absolute;top:50%;left:0%;transform:translate(0%,-50%);-ms-transform:translate(-0%,-50%)}
.w3-display-right{position:absolute;top:50%;right:0%;transform:translate(0%,-50%);-ms-transform:translate(0%,-50%)}

.chalk-border {
  border: 25px solid rgba(255,255,255,.8);
  border-image: url("https://www.unicefusa.org/sites/default/files/answer-box.png") 20;
}

.chalk-border:before {
  content: '';
  
}

.btn-primary{
  background-color: transparent !important;
}

.dropdown-menu.show{

  max-height: 25vh !important;
  overflow-y: auto !important;
}

.modal-transition-enter {
  width: 0;
  height: 0;
}

.modal-transition-enter-active {
  width: 100vw;
  height: 100vh;
  transition: width 500ms ease-in-out, height 500ms ease-in-out;
}

.modal-transition-exit {
  width: 100vw;
  height: 100vh;
}

.modal-transition-exit-active {
  width: 0;
  height: 0;
  transition: width 500ms ease-in-out, height 500ms ease-in-out;
}
